import { useEffect, useState } from "react";
import "../sass/menu.sass";
import iconClose from "../assets/close.svg";

function Menu({ isOpen, isVisible, setIsOpen, setIsVisible }) {
  const [open, setOpen] = useState("");
  const [visible, setVisible] = useState("");

  function handleClose(e) {
    e.preventDefault();
    setVisible("");
    setTimeout(() => {
      setOpen("");
    }, 100);
    setIsOpen(false);
    setIsVisible(false);
  }

  function handleClick(e) {
    e.preventDefault();
    handleClose(e)
    const target = e.target;
    const id = target.getAttribute("href");
    const element = document.querySelector(id);
    const top = element.offsetTop;
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    if (isOpen) {
      setOpen("active");
    }
    if (isVisible) {
      setVisible("visible");
    }
  }, [isOpen, isVisible]);

  return (
    <div className={`menu ${open} ${visible}`}>
      <div className="menu__close" onClick={handleClose}>
        <img src={iconClose} alt="Close"  />
      </div>
      <nav>
        <ul>
          <li>
            <a href="#acerca-de" onClick={handleClick}>
              ACERCA DE
            </a>
          </li>
          <li>
            <a href="#servicios" onClick={handleClick}>
              SERVICIOS
            </a>
          </li>
          <li>
            <a href="#casos-de-exito" onClick={handleClick}>
              CASOS DE ÉXITO
            </a>
          </li>
          <li>
            <a href="#clientes" onClick={handleClick}>
              CLIENTES
            </a>
          </li>
          <li>
            <a href="#contacto" onClick={handleClick}>
              CONTACTO
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Menu;
