import "../sass/about.sass";
import Wrapper from "./Wrapper";
import imgArrowDown from "../assets/arrow-down-01.svg";

function About() {
  return (
    <section className="about" id="acerca-de">
      <Wrapper size="xl">
        <div className="about__container">
          <div>
            <blockquote>The game changer agency on the go</blockquote>
            <div className="about__catchword">
              <div>Hacemos que terceros hablen bien de tu marca</div>
              <div>
                <div className="about__catchword__icon">
                  <img src={imgArrowDown} alt="Flecha hacia abajo" />
                </div>
              </div>
            </div>
          </div>
          <div className="about__container__text">
            <p>
              Somos una agencia independiente especializada en estrategias de{" "}
              <span>
                comunicación digital, influencer marketing, relaciones públicas
                y new media
              </span>{" "}
              en México y Latinoamérica.
            </p>
            <p>
              Estamos para ser una alternativa a la comunicación tradicional a
              través de estrategias digitales que buscan conectar con los nichos
              de las generaciones <span>millennials hasta centennials</span>{" "}
              interactuando en su mundo real, virtual y el metaverso.
            </p>
            <p>
              Tenemos un expertise de más de X años en la industrias de{" "}
              <span>
                Lifestyle Start Ups, Fashion, Sneakers, Pop Culture, Travel y
                Gastronomía
              </span>
            </p>
          </div>
        </div>
      </Wrapper>
    </section>
  );
}

export default About;
