import { useState } from "react";
import "../sass/nav.sass";
import Container from "./Container";
import Logo from "../assets/Logoblanco@300x.png";
import iconMenu from "../assets/Menu_blanco.svg";
import Menu from "../components/Menu";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    setIsOpen(true);
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }

  return (
    <>
      <nav className="navbar">
        <Container size="lg">
          <div className="navbar__content">
            <div className="navbar__brand">
              <img
                src={Logo}
                alt="Logo"
                className="footer__content__item__logo"
              />
            </div>
            <div className="navbar__menu">
              <img
                src={iconMenu}
                alt="Logo"
                className="footer__content__item__logo"
                onClick={handleClick}
              />
            </div>
          </div>
        </Container>
      </nav>
      <Menu isOpen={isOpen} isVisible={isVisible} setIsOpen={setIsOpen} setIsVisible={setIsVisible} />
    </>
  );
}

export default Nav;
