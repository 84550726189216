import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBH_pfQHsyw8Mk4kvPp8Y4DW7XyHMNtaFk",
  authDomain: "tegoo-15eb3.firebaseapp.com",
  projectId: "tegoo-15eb3",
  storageBucket: "tegoo-15eb3.appspot.com",
  messagingSenderId: "758261435131",
  appId: "1:758261435131:web:b2788b9cef29e861a5fe5e",
  measurementId: "G-J0GE2FX41N"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();
const storage = getStorage();

export { app, auth, db, storage };