import { useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import "../sass/brands.sass";

import { db, storage } from "../util/firebase";
import { ref, getDownloadURL } from "firebase/storage";

import { query, collection, orderBy, getDocs } from "firebase/firestore";

function Brands() {
  const [brands, setBrands] = useState([]);
  const [holder, setHolder] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getUrl(path) {
    let url = await getDownloadURL(ref(storage, path));
    return url;
  }

  useEffect(() => {
    (async () => {
      const q = query(collection(db, "brands"), orderBy("order"));
      const querySnapshot = await getDocs(q);
      let docs = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        const path = `brands/${doc.id}/${data.file}`;
        console.log(data.brand);
        docs.push({
          ...data,
          id: doc.id,
          path: path,
        });
      });
      setBrands(docs);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let docs = [];
      for (const brand of brands) {
        const url = await getUrl(brand.path);
        docs.push({ ...brand, url: url });
      }
      setHolder(docs);
    })();
  }, [setHolder, brands]);

  return (
    <section className="brands" id="clientes">
      <Wrapper size="xl">
        <div className="brands__container">
          <h2>Clientes</h2>

          <div className="brands__list">
            {holder.map((el) => (
              <div className="brands__list__item" key={el.id}>
                <img src={el.url} alt={el.brand} />
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </section>
  );
}

export default Brands;
