import "../sass/hero.sass";
import imgLeft from "../assets/home-left.jpg";
import imgRight from "../assets/home-right.jpg";
import imgLogoGradient from "../assets/logo-tegoo-gradient.svg";
import imgLogoLetter from "../assets/logo-tegoo-letter.svg";

function Hero() {
  return (
    <>
      <section className="hero">
        <div className="hero__content">
          <div className="hero__content__logo">
            <img src={imgLogoGradient} alt="Logotipo Tegoo" />
            <div className="hero__content__logo__animated">
              <img src={imgLogoLetter} alt="Logotipo Tegoo" />
            </div>
          </div>
          <h1 className="hero__content__desktop">
            Delightfully Wacky Solutions
          </h1>
          <h1 className="hero__content__mobile">
            Delightfully
            <br />
            Wacky
            <br />
            Solutions
          </h1>
        </div>
        <div className="hero__left">
          <img src={imgLeft} alt="Bacground" />
        </div>
        <div className="hero__right">
          <img src={imgRight} alt="Bacground" />
        </div>
      </section>
    </>
  );
}

export default Hero;
