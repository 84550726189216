import "../sass/contact.sass";
import Wrapper from "./Wrapper";

function Contact() {
  return (
    <section className="contact" id="contacto">
      <Wrapper size="lg">
        <div className="contact__container">
          <h2>Contáctanos</h2>
          <div className="contact__info">
            <div className="contact__info__item">hola@tegoo.mx</div>
            <div className="contact__info__item">
              <i className="lni lni-whatsapp"></i> 55 3677 8164
            </div>
          </div>
          <div className="contact__social">
            <div className="contact__social__icon">
              <i className="lni lni-facebook-filled"></i>
            </div>
            <div className="contact__social__icon">
              <i className="lni lni-instagram-original"></i>
            </div>
            <div className="contact__social__icon">
              <i className="lni lni-linkedin-original"></i>
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
}

export default Contact;
