import "../sass/values.sass";
import imgHands from "../assets/IMG3@300x-100.jpg";
import imgNoise from "../assets/IMG1@300x-100.jpg";
import imgFeets from "../assets/IMG4@300x-100.jpg";
import imgWaves from "../assets/IMG2@300x-100.jpg";

function Values() {
  return (
  <section className="values">
      <div className="values__item__conection">
        <h4 className="values__item__title">CONEXIÓN HUMANA</h4>
      </div>

      <div className="values__item__hands">
        <div>
          <img src={imgHands} alt="Hands" />
        </div>
      </div>

      <div className="values__item__comunity">
        <h4 className="values__item__title">COMUNIDAD + ALIANZA</h4>
      </div>

      <div className="values__item__noise">
        <div>
          <img src={imgNoise} alt="Noise"/>
        </div>
      </div>

      <div className="values__item__storytellers">
        <h4 className="values__item__title">STORYTELLERS</h4>
      </div>

      <div className="values__item__adaptable">
        <h4 className="values__item__title">ADAPTABLES</h4>
      </div>

      <div className="values__item__feets">
        <div>
          <img src={imgFeets} alt="Feets"/>
        </div>
      </div>

      <div className="values__item__waves">
        <div>
          <img src={imgWaves} alt="Waves" />
        </div>
      </div>

      <div className="values__item__freedom">
        <h4 className="values__item__title">LIBERTAD</h4>
      </div>
  </section>
  )
}

export default Values