import { useEffect, useRef } from "react";
import "../sass/services.sass";
import Wrapper from "./Wrapper";
import "../sass/accordion.sass";

function Services() {
  const refAccordion = useRef(null);

  useEffect(() => {
    const accordion = refAccordion.current;
    const accordionItems = accordion.querySelectorAll(".accordion__panel");
    const accordionHeaders = accordion.querySelectorAll(".accordion__tab");

    accordionHeaders.forEach((header, index) => {
      header.addEventListener("click", (e) => {
        if (e.currentTarget.classList.contains("active")) {
          e.currentTarget.classList.remove("active");
          accordionItems[index].classList.remove("active");
          return;
        }

        accordionHeaders.forEach((item) => item.classList.remove("active"));
        e.currentTarget.classList.toggle("active");
        accordionItems.forEach((item) => item.classList.remove("active"));
        accordionItems[index].classList.add("active");
      });
    });
  }, []);

  return (
    <section className="services" id="servicios">
      <Wrapper>
        <div className="accordion" ref={refAccordion}>
          <button className="accordion__tab">Influencer Marketing</button>
          <div className="accordion__panel">
            <p>
              Creamos campañas de comunicación enfocadas a creadores de
              contenido que amplifican y posicionan el mensaje de nuestras
              marcas{" "}
            </p>

            <ul>
              <li>• Mapeo y selección de talento •</li>
              <li>
                • Creación y ejecución de campañas con influencers pagados y
                orgánicos •{" "}
              </li>
              <li>• Identificación y contratación de talento •</li>
            </ul>
          </div>
          <button className="accordion__tab">Relaciones Públicas</button>
          <div className="accordion__panel">
            <p>
              Creamos estrategias de comunicación enfocadas a validar,
              amplificar y posicionar a nuestros cliente{" "}
            </p>

            <ul>
              <li>
                • Construcción y posicionamiento de marca en medios de
                comunicación •
              </li>
              <li>• Creación de contenidos editoriales • </li>
              <li>• Convocatoria de Prensa •</li>
              <li>• Relación con medios de comunicación •</li>
              <li>• Desarrollo y diseño de press kits • </li>
              <li>• Alianza con medios de comunicación •</li>
              <li>• Seeding •</li>
              <li>• Capacitación de voceros •</li>
              <li>• Gestión de entrevistas • </li>
              <li>• Manejo de crisis en medios© •</li>
            </ul>
          </div>
          <button className="accordion__tab">Comunicación Digital</button>
          <div className="accordion__panel">
            <p>
              Construcción de stortytelling y comunicación escrita para nuestros
              clientes{" "}
            </p>
            <ul>
              <li>• Manual de comunicación escrita de marca • </li>
              <li>• Mensajes y palabras clave •</li>
              <li>• Capacitaciones de Voceros • </li>
              <li>• Press Releases •</li>
              <li>• Biografías de Voceros • </li>
            </ul>
          </div>
          <button className="accordion__tab">Paid Stories</button>
          <div className="accordion__panel">
            <p>
              Coordinamos editoriales pagados en medios de comunicación
              enfocados a posicionar el mensaje de nuestros clientes{" "}
            </p>

            <ul>
              <li>
                • Gestión y negociación con el área comercial de medios de
                comunicación •{" "}
              </li>
              <li>
                • Creación de campañas en medios adhoc de acuerdo al presupuesto
                del cliente •{" "}
              </li>
              <li>• Follow up y reporte de resultados • </li>
            </ul>
          </div>
          <button className="accordion__tab">Producción de Eventos</button>
          <div className="accordion__panel">
            <p>(OFFLINE Y ONLINE)</p>
            <ul>
              <li>
                • Diseñamos eventos enfocados a crear un impacto en medios de
                comunicación, influencers y el consumidor •{" "}
              </li>
              <li>• Conceptualización y Diseño de eventos •</li>
              <li>• Desarrollo de experiencias a la medida •</li>
              <li>• Coordinación de eventos •</li>
              <li>• Diseño de Seeding • </li>
            </ul>
          </div>
          <button className="accordion__tab">Creación de Contenidos</button>
          <div className="accordion__panel">
            <p>
              Una imagen vale más que mil palabras, ayudamos a nuestros clientes
              que necesitan una construcción de imagen adhoc al storytelling{" "}
            </p>
            <ul>
              <li>• Creación de contenido audiovisual •</li>
              <li>• Shootings •</li>
              <li>• Advertorials •</li>
              <li>• Fotos de producto •</li>
              <li>• Fotos editoriales •</li>
              <li>• Fotos de platillos •</li>
              <li>• Contenidos Originales •</li>
            </ul>
          </div>
        </div>
      </Wrapper>
    </section>
  );
}

export default Services;
