import "../sass/footer.sass";
import Container from "./Container";
import Logo from "../assets/Logoblanco@300x.png";
import { useState } from "react";

function Footer() {
  const [year] = useState(new Date().getFullYear());

  return (
    <footer className="footer">
      <Container size="lg">
        <div className="footer__content">
          <div className="footer__content__item">
            <img
              src={Logo}
              alt="Logo"
              className="footer__content__item__logo"
            />
            <span>© {year}</span>
          </div>
          <div className="footer__content__item">
            <span>hola@tegoo.mx </span>
            <span>55 3677 8164</span>
          </div>
          <div className="footer__content__item">
            <span>Teggo </span>
            <span>Mexico City</span>
          </div>
          <div className="footer__content__item">
            <span>Siguenos en redes</span>
            <div className="footer__content__item__social">
              <div className="footer__content__item__social__icon">
                <i className="lni lni-facebook-filled"></i>
              </div>
              <div className="footer__content__item__social__icon">
                <i className="lni lni-instagram-original"></i>
              </div>
              <div className="footer__content__item__social__icon">
                <i className="lni lni-linkedin-original"></i>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
