import About from "../components/About";
import Brands from "../components/Brands";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Services from "../components/Services";
import Values from "../components/Values";
import Proyects from "../components/Proyects";
import Nav from "../components/Nav";

function Home() {
  return (
    <>
      <Nav />
      <Hero />
      <About />
      <Services />
      <Values />
      {/* <Proyects /> */}
      <Brands />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
